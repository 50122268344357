import { Checkbox, Button, Divider } from 'antd';
import React, { useEffect, useState } from 'react';
import useLocalStorage from '@ninja/hooks/useLocalStorage';

const ColumnsCustomize = ({
  customizationId,
  onUpdate = () => { },
  columns,
  customizationEdit,
  removeColumns = [],
}) => {
  const [tmpColumns, setTmpColumns] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [localColumns, setLocalColumns] = useLocalStorage({ key: customizationId });

  const updatedObject = { ...tmpColumns };

  for (const columnId of removeColumns) {
    if (updatedObject[columnId] && customizationEdit !== null) {
      updatedObject[columnId] = { ...updatedObject[columnId], hidden: true };
    }
  }

  useEffect(() => {
    const tempCustomColumns = {
      ...localColumns,
    };

    columns.forEach((c) => {
      if (!tempCustomColumns[c.key]) {
        tempCustomColumns[c.key] = { hidden: false, name: c.title || c.name };
      }
    });

    setLocalColumns(tempCustomColumns);
  }, [columns]);

  useEffect(() => {
    setTmpColumns(localColumns);
    onUpdate(updatedObject);
  }, [localColumns, customizationEdit]);

  const onCustomizeSave = () => {
    setLocalColumns(tmpColumns);
    onUpdate(updatedObject);
  };

  const handleChange = (columnId, checked) => {
    setTmpColumns((ps) => ({
      ...ps,
      [columnId]: {
        ...ps[columnId],
        hidden: !checked,
      },
    }));
  };

  const handleSelectAllChange = (checked) => {
    let colIds = Object.keys(updatedObject);
    setSelectAll(checked);

    colIds.forEach((columnId) => handleChange(columnId, checked));
  };

  return (
    <div className='min-w-64'>
      <div>
        <Checkbox
          variant='outlined'
          text='Select All'
          checked={selectAll}
          onChange={(e) => {
            handleSelectAllChange(e.target.checked);
          }}
        >
          Select All
        </Checkbox>
        <Divider style={{ borderColor: '#E5E5E5' }} />
        <div className='grid max-h-80 h-full overflow-y-auto'>
          {Object.entries(updatedObject).map(([columnId, column]) => {
            const label = typeof column?.name === 'string' ? column.name : columnId;
            return (
              <div className='align-center grid ' key={columnId}>
                <Checkbox
                  variant='outlined'
                  text={label}
                  checked={!column.hidden}
                  onChange={(e) => {
                    handleChange(columnId, e.target.checked);
                  }}
                >
                  {label}
                </Checkbox>
              </div>
            );
          })}
        </div>
      </div>
      <Divider style={{ borderColor: '#E5E5E5' }} />
      <div className='grid justify-end '>
        <Button type='primary' onClick={onCustomizeSave}>
          Save
        </Button>
      </div>
    </div>
  );
};

export default ColumnsCustomize;
