import React from 'react';
import { useListShopifyQuery } from 'modules/apps/apps/ShopifyApp/api/shopify.api';
import { Select, Space, Checkbox } from 'antd';
import PropTypes from 'prop-types';

const ShopifySelect = (props) => {
  const { onChange, value = [] } = props;
  const { data = [], isFetching } = useListShopifyQuery();

  const handleChange = (newValue) => {
    onChange?.(newValue);
  };

  const selectAllChanged = (e) => {
    if (e.target.checked) {
      handleChange?.(data.data.map((opt) => opt.id));
    } else {
      handleChange?.([]);
    }
  };

  if (isFetching) {
    return null;
  }

  const allSelected = data?.data.length === value.length;

  return (
    <Select
      value={value}
      placeholder='Select Shopify'
      onChange={handleChange}
      className='w-full'
      mode='multiple'
      maxTagCount='responsive'
      dropdownRender={(menu) => (
        <div style={{ minWidth: '200px' }}>
          <Checkbox checked={allSelected} className='text-xs font-normal mb-2 ml-2' onChange={selectAllChanged}>
            Select All
          </Checkbox>
          {menu}
        </div>
      )}
    >
      {data.data?.map((opt) => (
        <Select.Option value={opt.id} label={opt.name} key={opt.id}>
          <Space>
            <span role='img' aria-label={opt.name}>
              <img src='/icons/integrations/shopify.svg' className='w-5' />
            </span>
            {opt.name}
          </Space>
        </Select.Option>
      ))}
    </Select>
  );
};

export default ShopifySelect;
ShopifySelect.propTypes = {
  onChange: PropTypes.object.isRequired,
  value: PropTypes.array.isRequired,
};
