import React from 'react';
import { mdiFilterOutline, mdiTrashCanOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { useNinjaTable } from 'components/tables/NinjaTable/useNinjaTable';
import {
  useListFiltersQuery,
  useDeleteFilterMutation,
} from 'store/redux/apis/workspace/workspace.api';
import ninjaApi from 'store/redux/apis';
import { snackbar } from 'components/notifications/notifications';
import Ninja from '@ninja';
import { Button } from 'antd';

const SavedFilter = () => {
  const { filters, setFilters, savedFiltersParams } = useNinjaTable();
  const [getGetSavedFilterQuery] = ninjaApi.useLazyGetSavedFilterQuery();
  const { data: savedFilters = [] } = useListFiltersQuery(savedFiltersParams);
  const [deleteFilter, { isLoading }] = useDeleteFilterMutation();

  const toggleFilter = async (id) => {
    let newFilters = {};
    if (id !== filters?._filterId) {
      const res = await getGetSavedFilterQuery({ filter_id: id }).unwrap();
      newFilters = { ...res.filters, _filterId: res.id };
    }
    setFilters(newFilters);
  };

  const deleteSavedFilter = async (filterId) => {
    await deleteFilter({ filter_id: filterId })
      .unwrap()
      .then(() => {
        snackbar.success('Saved filter successfully deleted');
      });
  };

  return (
    <div className='w-full'>
      <div className='grid gap-2'>
        {Array.isArray(savedFilters?.data)
          ? savedFilters?.data?.map((f, index) => (
              <div className='flex items-center justify-between gap-2' key={index}>
                <Button
                  key={f.id}
                  color={filters?._filterId === f.id ? 'secondary' : 'default'}
                  onClick={() => {
                    toggleFilter(f.id);
                  }}
                  className={
                    filters?._filterId === f.id ? 'text-orange w-full' : 'text-gray w-full'
                  }
                  icon={
                    <Icon
                      path={mdiFilterOutline}
                      className={
                        filters?._filterId === f.id ? 'text-orange mr-2' : 'text-gray mr-2'
                      }
                    />
                  }
                >
                  <span
                    className={
                      filters?._filterId === f.id
                        ? 'text-orange text-xs font-normal font-medium'
                        : 'text-gray text-xs font-normal'
                    }
                  >
                    {f.name}
                  </span>
                </Button>
                <Button
                  type='text'
                  loading={filters?._filterId === f.id && isLoading}
                  onClick={() => deleteSavedFilter(f.id)}
                  icon={<Icon color={Ninja.colors.mediumBlue} path={mdiTrashCanOutline} />}
                />
              </div>
            ))
          : null}
      </div>
    </div>
  );
};
export default SavedFilter;