import { Pagination } from '@material-ui/lab';
import React from 'react';
import { useNinjaTable } from 'components/tables/NinjaTable/useNinjaTable';
import { Select } from 'antd';

const NinjaTableFooter = (props) => {
  const {
    showPagination = true,
    showPerPage = true,
    totalDataNum = false,
    perPages = defaultPerPages,
  } = props;
  const { pagination, setPagination } = useNinjaTable();

  // When page size changes
  const onPageSizeChange = (val) => {
    setPagination({ ...pagination, per_page: Number(val), page: 1 });
  };

  // When page changes
  const onPageChange = (_, val) => {
    setPagination({ ...pagination, page: Number(val) });
  };

  return (
    <div className='px-4 py-4 nnj-grid-footer'>
      <div className='flex items-center gap-4'>
        {totalDataNum && pagination?.total > 0 && (
          <p className='!text-color-light !text-xs m-auto'>
            Showing {pagination.page} to{' '}
            {Math.round((pagination.total / pagination.total_pages) * pagination.page)} of{' '}
            {pagination.total}
          </p>
        )}
        {showPerPage ? (
          <Select
            className='h-9 min-w-16'
            value={pagination?.per_page}
            name='order_by'
            options={perPages}
            onChange={onPageSizeChange}
          />
        ) : null}
      </div>
      {showPagination && pagination?.total_pages > 1 ? (
        <Pagination
          count={pagination?.total_pages || 0}
          page={pagination?.page || 0}
          onChange={onPageChange}
          color='secondary'
          variant='outlined'
          shape='rounded'
        />
      ) : null}
    </div>
  );
};

/**
 * Default page sizes
 */
const defaultPerPages = [
  { value: 10, label: '10' },
  { value: 20, label: '20' },
  { value: 25, label: '25' },
  { value: 50, label: '50' },
  { value: 100, label: '100' },
  { value: 250, label: '250' },
  { value: 500, label: '500' },

]

export default NinjaTableFooter;
