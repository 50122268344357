import React, { useState } from 'react';
import AutomaticDrawer from 'components/Drawers/AutomaticDrawer';
import { Form, Input, Button, Collapse } from 'antd';
import formikFieldToAntProps from '@lib/formik/formikFieldToAntProps';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { snackbar } from 'components/notifications/notifications';
import useQueryParams from 'hooks/useQueryParams';
import { useCreateContactMutation } from '../api/crm.api';
import DatePicker from 'components/DatePicker';
import GenderSelect from 'components/Selects/GenderSelect';
import CountriesSelect from 'components/Selects/CountriesSelect';
import { UpOutlined, RightOutlined } from '@ant-design/icons';
import Icon from '@mdi/react';
import { mdiPlusCircle, mdiClose } from '@mdi/js';

const CreateContactDrawer = (props) => {
  const { close: closeContactsDrawer } = CreateContactDrawer.useDrawer();

  const { setUrlParams, searchParams } = useQueryParams();
  const { create_deal } = searchParams;
  const [createContact, { isLoading }] = useCreateContactMutation();

  const handleCreateContact = (values) => {
    createContact(values)
      .unwrap()
      .then((data) => {
        closeContactsDrawer();

        if (create_deal) {
          setUrlParams({ contact_id: data.id, contact_email: data.email });
        }
        snackbar.success('Contact created successfully');
      });
  };

  const form = useFormik({
    initialValues: {
      contact_type: 'P',
      first_name: '',
      last_name: '',
      email: '',
    },
    onSubmit: handleCreateContact,
    validationSchema: validationSchema,
    validateOnChange: false,
  });

  const formFields = {
    middle_name: { placeholder: 'Middle Name', type: 'text' },
    date_of_birth: { placeholder: 'Date of Birth', type: 'date' },
    gender: { placeholder: 'Gender', type: 'select' },
    city: { placeholder: 'City', type: 'text' },
    state: { placeholder: 'State', type: 'text' },
    postal_code: { placeholder: 'Postal Code', type: 'number' },
    address_2: { placeholder: 'Address 2', type: 'text' },
    job_title: { placeholder: 'Job Title', type: 'text' },
    security_number: { placeholder: 'Security Number', type: 'number' },
    emergency_phone: { placeholder: 'Emergency Phone', type: 'number' },
  };

  const [activeKey, setActiveKey] = useState(null);
  const handleChange = (key) => {
    setActiveKey(key);
  };

  return (
    <AutomaticDrawer size='large' title='Create Contact' {...props}>
      <Form
        className='flex flex-col gap-2 justify-center'
        gap-2
        layout='vertical'
        onFinish={handleCreateContact}
      >
        <div className='grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-x-4'>
          <Form.Item
            label='Email'
            hasFeedback
            {...formikFieldToAntProps(form.errors, form.touched, 'email')}
          >
            <Input
              type='Email'
              onChange={(e) => {
                form.setFieldValue('email', e.target.value);
              }}
              onBlur={form.handleBlur}
              value={form.values.email}
              size='large'
              placeholder='Email'
              name='email'
            />
          </Form.Item>
          <Form.Item
            label='First Name'
            hasFeedback
            {...formikFieldToAntProps(form.errors, form.touched, 'first_name')}
          >
            <Input
              type='text'
              onChange={(e) => {
                form.setFieldValue('first_name', e.target.value);
              }}
              onBlur={form.handleBlur}
              value={form.values.first_name}
              size='large'
              placeholder='First Name'
              name='first_name'
            />
          </Form.Item>
          <Form.Item
            label='Last Name'
            hasFeedback
            {...formikFieldToAntProps(form.errors, form.touched, 'last_name')}
          >
            <Input
              type='text'
              onChange={(e) => {
                form.setFieldValue('last_name', e.target.value);
              }}
              onBlur={form.handleBlur}
              value={form.values.last_name}
              size='large'
              placeholder='Last Name'
              name='last_name'
            />
          </Form.Item>
          <Form.Item label='Gender'>
            <GenderSelect
              size='large'
              onChange={(e) => {
                form.setFieldValue('gender', e);
              }}
            />
          </Form.Item>
          <Form.Item label='Personal Number'>
            <Input
              type='number'
              onChange={(e) => {
                form.setFieldValue('personal_number', e.target.value);
              }}
              onBlur={form.handleBlur}
              value={form.values.personal_number}
              size='large'
              placeholder='Personal Number'
              name='personal_number'
            />
          </Form.Item>
          <Form.Item label='Company'>
            <Input
              type='text'
              onChange={(e) => {
                form.setFieldValue('company', e.target.value);
              }}
              onBlur={form.handleBlur}
              value={form.values.company}
              size='large'
              placeholder='Company'
              name='company'
            />
          </Form.Item>
          <Form.Item label='Country'>
            <CountriesSelect
              value={form.values.country}
              onChange={(code) => {
                form.setFieldValue('country', code);
              }}
              size='large'
              popupMatchSelectWidth={false}
              autocomplete='one-time-code'
            />
          </Form.Item>
          <Form.Item label='Phone Number'>
            <Input
              className='w-full'
              type='number'
              onChange={(e) => {
                form.setFieldValue('phone', e.target.value);
              }}
              onBlur={form.handleBlur}
              value={form.values.phone}
              size='large'
              placeholder='Phone Number'
              name='phone'
            />
          </Form.Item>
          <Form.Item label='Address 1'>
            <Input
              className='w-full'
              type='text'
              onChange={(e) => {
                form.setFieldValue('address_1', e.target.value);
              }}
              onBlur={form.handleBlur}
              value={form.values.address_1}
              size='large'
              placeholder='Address 1'
              name='address_1'
            />
          </Form.Item>
        </div>
        <Collapse
          activeKey={activeKey}
          onChange={handleChange}
          className='reverse font-normal text-sm'
          expandIcon={({ isActive }) => (isActive ? <UpOutlined /> : <RightOutlined />)}
          destroyInactivePanel={true}
          expandIconPosition='right'
        >
          <Collapse.Panel
            key='1'
            header={
              activeKey?.includes('1') ? (
                <div className='flex items-center gap-2'>
                  <Icon path={mdiClose} size={0.8} /> Close
                </div>
              ) : (
                <div className='flex items-center gap-2'>
                  <Icon path={mdiPlusCircle} size={0.8} /> More Fields
                </div>
              )
            }
          >
            <div className='grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-x-4 mt-2'>
              {Object.entries(formFields).map(([key, { placeholder, type }]) => {
                if (type === 'text' || type === 'number') {
                  return (
                    <Form.Item key={key} label={placeholder} className='w-full'>
                      <Input
                        className='w-full'
                        type={type}
                        onChange={(e) => {
                          form.setFieldValue(key, e.target.value);
                        }}
                        onBlur={form.handleBlur}
                        value={form.values[key]}
                        size='large'
                        placeholder={placeholder}
                        name={key}
                      />
                    </Form.Item>
                  );
                }

                if (type === 'date') {
                  return (
                    <Form.Item key={key} label={placeholder} className='w-full'>
                      <DatePicker
                        size='large'
                        name={key}
                        value={form.values[key]}
                        placeholder={placeholder}
                        onChange={(val) => {
                          form.setFieldValue(key, val);
                        }}
                      />
                    </Form.Item>
                  );
                }

                return null;
              })}
            </div>
          </Collapse.Panel>
        </Collapse>
        <div className='flex-center'>
          <Button onClick={form.handleSubmit} size='large' type='primary' loading={isLoading}>
            Create
          </Button>
        </div>
      </Form>
    </AutomaticDrawer>
  );
};

CreateContactDrawer.requiredParams = {
  create_contact: (create_contact) => create_contact == 1,
};

CreateContactDrawer.useDrawer = () => {
  const { setUrlParams, removeParams } = useQueryParams();

  const open = () => setUrlParams({ create_contact: 1 });
  const close = () => removeParams(['create_contact']);

  return {
    open,
    close,
  };
};

const validationSchema = yup.object({
  first_name: yup.string('Enter your name').min(1, 'Enter Your Name').required('Enter your name'),
  last_name: yup.string('Enter your last name').required('Enter your last name'),
  email: yup.string('Enter your email').email('Enter a valid email').required('Email is required'),
});

export default CreateContactDrawer;
