import React from 'react';
import { useListWoocommerceQuery } from 'modules/apps/apps/WoocommerceApp/api/woocommerce.api';
import { Select, Space, Checkbox } from 'antd';
import PropTypes from 'prop-types';

const WoocommerceAccountSelect = (props) => {
  const { onChange, value = [] } = props;
  const { data = [], isFetching } = useListWoocommerceQuery();

  const handleChange = (newValue) => {
    onChange?.(newValue);
  };

  const selectAllChanged = (e) => {
    if (e.target.checked) {
      handleChange?.(data.map((opt) => opt.id));
    } else {
      handleChange?.([]);
    }
  };

  if (isFetching) {
    return null;
  }

  const allSelected = data?.length === value.length;

  return (
    <Select
      value={value}
      className='w-full'
      placeholder='Select Woocommerce'
      onChange={handleChange}
      mode='multiple'
      maxTagCount='responsive'
      dropdownRender={(menu) => (
        <div style={{ minWidth: '200px' }}>
          <Checkbox checked={allSelected} className='text-xs font-normal mb-2 ml-2' onChange={selectAllChanged}>
            Select All
          </Checkbox>
          {menu}
        </div>
      )}
    >
      {data?.map((opt) => (
        <Select.Option value={opt.id} label={opt.name} key={opt.id}>
          <Space>
            <span role='img' aria-label={opt.name}>
              <img src='/icons/integrations/woocommerce.svg' className='w-5' />
            </span>
            {opt.name}
          </Space>
        </Select.Option>
      ))}
    </Select>
  );
};

export default WoocommerceAccountSelect;

WoocommerceAccountSelect.propTypes = {
  onChange: PropTypes.object.isRequired,
  value: PropTypes.array.isRequired,
};
